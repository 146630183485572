import React, { useState, useEffect } from 'react';
import './LeadDetail.css';
import Sidebar from '../../components/Sidebar';
import NavBar from '../../components/Navbar';
import { useParams } from 'react-router-dom';
import { usePersistentCollapsedState } from './../../components/SidebarContext';
import api from './../../api/axios';
import { useTranslation } from 'react-i18next';


const LeadDetail = () => {
  const { id } = useParams();
  const [lead, setLead] = useState({});
  const collapsed = usePersistentCollapsedState();

  const { t } = useTranslation();

  const fetchLead = async () => {
    try {
      const response = await api.get(`/leads/lead/${id}`);
      setLead(response.data);
    } catch (error) {
      console.error('Error fetching lead:', error.response?.data || error.message);
    }
  };

  useEffect(() => {
    fetchLead();
  }, [id]);



  return (
    <div className={`dashboard ${collapsed ? 'collapsed' : ''}`}>
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row page-row">
          <div className="campaigns col-md-12">
            <div className='activities-widget'>
              <div className="campaign-detail">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                  <h2>{ lead.leadName }</h2>
                  {lead.profileImageUrl &&
                    <img
                      src={lead.profileImageUrl}
                      alt={t('leads.avatar')}
                      width="80"
                      height="80"
                    />
                  }
                  <p>{lead.profileUrl}</p>
                  <p>{lead.leadListTitle}</p>
    </div>

    
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadDetail;
