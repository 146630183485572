import React, { useState, useEffect } from 'react';
import api from './../api/axios';
import Userfront from "@userfront/toolkit/react";

const getCurrentTimezoneOffset = () => {
  const date = new Date();
  const janOffset = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  const julOffset = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
  return janOffset === julOffset ? janOffset : (janOffset > julOffset ? -1 : 1);
};

const determineEffectiveTimezone = () => {
  const offset = getCurrentTimezoneOffset();
  if (offset === -120) return 'UTC+2';
  if (offset === -60) return 'UTC+1';
  return 'UTC';
};

const timezones = [
  { value: 'UTC-12', label: 'UTC-12', cities: ['Baker Island'] },
  { value: 'UTC-11', label: 'UTC-11', cities: ['American Samoa'] },
  { value: 'UTC-10', label: 'UTC-10', cities: ['Hawaii', 'Honolulu'] },
  { value: 'UTC-9', label: 'UTC-9', cities: ['Alaska'] },
  { value: 'UTC-8', label: 'UTC-8', cities: ['Los Angeles', 'San Francisco'] },
  { value: 'UTC-7', label: 'UTC-7', cities: ['Denver', 'Phoenix'] },
  { value: 'UTC-6', label: 'UTC-6', cities: ['Chicago', 'Houston'] },
  { value: 'UTC-5', label: 'UTC-5', cities: ['New York', 'Toronto'] },
  { value: 'UTC-4', label: 'UTC-4', cities: ['Caracas', 'Santiago'] },
  { value: 'UTC-3', label: 'UTC-3', cities: ['Brasília', 'Buenos Aires'] },
  { value: 'UTC-2', label: 'UTC-2', cities: ['South Georgia'] },
  { value: 'UTC-1', label: 'UTC-1', cities: ['Azores'] },
  { value: 'UTC', label: 'UTC', cities: ['London', 'Reykjavik'] },
  { value: 'UTC+1', label: 'UTC+1', cities: ['Berlin', 'Paris', 'Amsterdam'] },
  { value: 'UTC+2', label: 'UTC+2', cities: ['Amsterdam (during CEST)', 'Athens', 'Cairo'] },
  { value: 'UTC+3', label: 'UTC+3', cities: ['Moscow', 'Nairobi'] },
  { value: 'UTC+4', label: 'UTC+4', cities: ['Dubai', 'Baku'] },
  { value: 'UTC+5:30', label: 'UTC+5:30', cities: ['New Delhi', 'Mumbai'] },
  { value: 'UTC+8', label: 'UTC+8', cities: ['Beijing', 'Shanghai'] },
  { value: 'UTC+9', label: 'UTC+9', cities: ['Tokyo', 'Osaka'] },
];

const TimezoneSelector = ({ campaignTimezone, setTimezone, t }) => {
  const [defaultTimezone, setDefaultTimezone] = useState(determineEffectiveTimezone());
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const email = Userfront.user.email;
        const userResponse = await api.get(`/user?email=${email}`);
        setCurrentUser(userResponse.data.user);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    if (!campaignTimezone) {
      const fallbackTimezone = currentUser?.timezone || defaultTimezone;
      setTimezone(fallbackTimezone);
    }
  }, [campaignTimezone, currentUser, defaultTimezone, setTimezone]);

  return (
    <div className="form-group">
      <label htmlFor="timezone">{t('settings.timezone')}</label>
      <select 
        id="timezone" 
        className="form-control" 
        value={campaignTimezone || currentUser?.timezone || defaultTimezone} 
        onChange={(e) => setTimezone(e.target.value)}
      >
        {timezones.map(zone => (
          <option key={zone.value} value={zone.value}>
            {zone.label} ({zone.cities.join(', ')})
          </option>
        ))}
      </select>
    </div>
  );
};

export { TimezoneSelector, determineEffectiveTimezone };
