import ReactFlow, { Background, Controls, MiniMap } from 'reactflow';
import 'reactflow/dist/style.css';
import CustomNode from './CustomNode';

const nodeTypes = {
  customNode: CustomNode,
};

const CampaignWorkflow = ({ campaign, tasks }) => {
  if (!campaign?.configuredWorkflow) return <p>No workflow configured.</p>;

  const nodes = campaign.configuredWorkflow.nodes.map((node) => {
    const nodeTasks = tasks.filter((task) => task.nodeId === node.id);
    const taskCounts = {
      waiting: nodeTasks.filter((task) => task.status === "waiting").length,
      pending: nodeTasks.filter((task) => task.status === "pending").length,
      processing: nodeTasks.filter((task) => task.status === "processing").length,
      completed: nodeTasks.filter((task) => task.status === "completed").length,
    };

    return {
      id: node.id,
      type: 'customNode',
      position: { x: node.position.x, y: node.position.y },
      data: {
        label: node.data.label,
        taskCounts,
        isStart: node.type === "start",
        isEnd: node.type === "end",
        totalLeads: node.type === "start"
          ? tasks.filter(task => task.status === "waiting").length +
            (tasks.filter(task => task.status === "pending").length === 1 ? 1 : 0)
          : node.type === "end"
          ? tasks.filter(task => 
              !["waiting", "pending", "processing"].includes(task.status)).length
          : null,
      },
      className: node.className, // Apply the same CSS class as in configured workflow
    };
  });

  const edges = campaign.configuredWorkflow.edges.map((edge) => ({
    id: `edge-${edge.source}-${edge.target}`,
    source: edge.source,
    target: edge.target,
  }));

  return (
    <div className="workflow-container">
      <ReactFlow nodes={nodes} edges={edges} nodeTypes={nodeTypes} fitView>
        <Background />
      </ReactFlow>
    </div>
  );
};

export default CampaignWorkflow;
