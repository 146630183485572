import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';
import api from './../api/axios';
import './LogChart.css';
import Userfront from '@userfront/toolkit/react';
import { useTranslation } from 'react-i18next';

const LimitsChart = () => {
  const [series, setSeries] = useState([0, 0, 0]);
  const [actualValues, setActualValues] = useState([0, 0, 0]); // Store actual values
  const [maxLimits, setMaxLimits] = useState(null);
  const email = Userfront.user.email;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await api.get(`/user?email=${email}`);
        const currentUser = userResponse.data.user;

        const connectionLimit = currentUser.connectionLimit || 40;
        const profileViewLimit = currentUser.profileViewLimit || 40;
        const messagesLimit = currentUser.messagesLimit || 40;

        const logsResponse = await api.get(`/logs/${email}`);
        const logs = logsResponse.data;

        const todayStart = dayjs().startOf('day').valueOf();
        const todayEnd = dayjs().endOf('day').valueOf();

        let profileViewsCount = 0;
        let connectionRequestsCount = 0;
        let messagesCount = 0;

        logs.forEach(log => {
          const logTimestamp = new Date(log.timestamp).getTime();
          if (logTimestamp >= todayStart && logTimestamp <= todayEnd) {
            if (log.action === 'viewProfile') {
              profileViewsCount += 1;
            } else if (log.action === 'sendConnectionRequest') {
              connectionRequestsCount += 1;
            } else if (log.action === 'sendMessage') {
              messagesCount += 1;
            }
          }
        });

        const actualVals = [
          connectionRequestsCount,
          profileViewsCount,
          messagesCount,
        ];

        const maxVals = [
          parseInt(connectionLimit, 10),
          parseInt(profileViewLimit, 10),
          parseInt(messagesLimit, 10),
        ];

        // Convert actual values to percentages
        const percentageVals = actualVals.map((val, index) =>
          maxVals[index] > 0 ? (val / maxVals[index]) * 100 : 0
        );

        setActualValues(actualVals);
        setMaxLimits(maxVals);
        setSeries(percentageVals); // Use percentage for the bar
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, [email]);

  const options = {
    chart: {
      height: 390,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
        },
        dataLabels: {
          show: true,
          name: {
            formatter: (seriesName, opts) => {
              const index = opts.seriesIndex;
              if (maxLimits) {
                return `${t(`home.${opts.w.globals.labels[index]}`)}: ${actualValues[index]}/${maxLimits[index]}`;
              }
              return `${seriesName}: 0/0`;
            },
            offsetX: -10,
            offsetY: -10,
          },
          value: {
            show: true,
            formatter: (val, opts) => {
              const index = opts.seriesIndex;
              return `${actualValues[index]}/${maxLimits[index]}`;
            },
          },
          total: {
            show: false,
          },
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
    colors: ['#1ab7ea', '#0084ff', '#39539E'],
    labels: [t('home.invites'), t('home.profileViews'), t('home.messages')],
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '12px',
            itemMargin: {
              vertical: 2,
            },
          },
        },
      },
    ],
    legend: {
      show: true,
      floating: true,
      position: 'left',
      offsetX: -50,
      offsetY: 25,
      fontSize: '16px',
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName, opts) {
        const index = opts.seriesIndex;
        return `${seriesName}: ${actualValues[index]}/${maxLimits[index]}`;
      },
      itemMargin: {
        vertical: 3,
      },
    },
  };

  if (!maxLimits) {
    return <div>{t('home.loading')}</div>;
  }

  return (
    <div className="limits-chart">
      <ReactApexChart
        options={options}
        series={series} // Using percentages for the bars
        type="radialBar"
        height={400}
      />
    </div>
  );
};

export default LimitsChart;
