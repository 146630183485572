import React, { useState, useEffect } from 'react';
import './CampaignDetail.css';
import CampaignWorkflow from './CampaignWorkflow';
import Sidebar from '../../components/Sidebar';
import NavBar from '../../components/Navbar';
import axios from 'axios';
import Userfront from "@userfront/toolkit/react";
import { useParams } from 'react-router-dom';
import { usePersistentCollapsedState } from './../../components/SidebarContext';
import SaveRestore from '../../components/TemplateBuilder';
import api from './../../api/axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { TimezoneSelector, determineEffectiveTimezone } from '../../components/TimezoneSelector';
import { FaClock } from 'react-icons/fa';
import { IoMdArrowDropdown } from 'react-icons/io';
import AlertComponent from './../../components/Alert';
import { Spinner } from 'react-bootstrap';
import { FaCogs } from 'react-icons/fa';
import { faUsers, faUserPlus, faEnvelope, faComments } from '@fortawesome/free-solid-svg-icons';


const CampaignDetail = () => {
  const { id } = useParams();
  const [selectedLeadListId, setSelectedLeadListId] = useState('');
  const [leadLists, setLeadLists] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [campaign, setCampaign] = useState({});
  const collapsed = usePersistentCollapsedState();
  const [statusMessage, setStatusMessage] = useState(null);
  
  const [currentUser, setCurrentUser] = useState(1);
  const [message, setMessage] = useState(null);
  
  const [isSaving, setIsSaving] = useState(false);
  const [campaignTimezone, setCampaignTimezone] = useState(currentUser?.timezone || determineEffectiveTimezone());


  const handleCloseAlert = () => {
    setMessage(null);
  };

  const defaultSchedule = {
    Monday: { active: true, start: '09:00', end: '18:00' },
    Tuesday: { active: true, start: '09:00', end: '18:00' },
    Wednesday: { active: true, start: '09:00', end: '18:00' },
    Thursday: { active: true, start: '09:00', end: '18:00' },
    Friday: { active: true, start: '09:00', end: '18:00' },
    Saturday: { active: false, start: '09:00', end: '18:00' },
    Sunday: { active: false, start: '09:00', end: '18:00' },
  };

  const [schedule, setSchedule] = useState(defaultSchedule);


  const [tasks, setTasks] = useState([]);
  
  const { t } = useTranslation();
   
  

  const fetchUser = async () => {
    try {
      const email = Userfront.user.email;
      const userResponse = await api.get(`/user?email=${email}`);
      setCurrentUser(userResponse.data.user);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };


  const fetchLeadLists = async () => {
    try {
      const user = await Userfront.user.email;
      const response = await api.get(`/lead-lists?user=${user}`);
      setLeadLists(response.data);
    } catch (error) {
      console.error('Error fetching lead lists:', error.response?.data || error.message);
    }
  };

  const fetchCampaign = async () => {
    try {
        const response = await api.get(`/campaigns/${id}`);
        const campaignData = response.data;
        
        setCampaign(campaignData);

        // Ensure selectedLeadListId is updated only if it's not manually changed
     //   setSelectedLeadListId(campaignData.leadList || '');

        // If campaign status is NOT 'Concept', move to step 4
        if (campaignData.status !== 'Concept') {
            setCurrentStep(4);
        }

    } catch (error) {
        console.error('Error fetching campaign:', error.response?.data || error.message);
    }
};

useEffect(() => {
  if (campaign.leadList && leadLists.length > 0) {
    const matchingLeadList = leadLists.find(leadList => leadList._id === campaign.leadList);
    if (matchingLeadList) {
      setSelectedLeadListId(matchingLeadList._id);
    }
  }
}, [campaign, leadLists]); // ✅ Ensures `selectedLeadListId` updates only when both values are available




  const fetchTasks = async () => {
    try {
      const { data } = await api.get(`/campaign/${id}/tasks`);
      setTasks(data);

      console.log("Current tasks: " + JSON.stringify(tasks));

    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    fetchUser();
    fetchLeadLists();
    fetchCampaign();
    fetchTasks();
  }, [id]);


  useEffect(() => {
    if (currentUser && campaign?.timezone) {
      setCampaignTimezone(campaign.timezone);
    } else {

      if (currentUser) {
      setCampaignTimezone(currentUser.timezone); // Use user's default timezone
      };

    }
  
    if (campaign?.schedule) {
      setSchedule(campaign.schedule);
    }
  }, [campaign]);

  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStepTitle = (step) => {
    switch (step) {
      case 1:
        return t('campaign.step1');
      case 2:
        return t('campaign.step2');
      case 3:
        return t('campaign.step3');
      case 4:
        return t('campaign.step4');
      default:
        return '';
    }
  };

  const getProgressBarWidth = (step) => {
    const stepWidth = 25;
    return step <= currentStep ? `${stepWidth}%` : '0%';
  };

  const handleLeadListChange = (e) => {
    const updatedValue = e.target.value;
    setSelectedLeadListId(updatedValue);
  };

  const updateCampaignWithLeadList = async () => {


    if (isSaving) return; // Prevent multiple clicks

    setIsSaving(true);

    try {
      await api.post(`/campaigns/${id}`, {
        leadListId: selectedLeadListId,
      });

      setMessage({ type: 'success', text: t('campaigns.campaignUpdateLeadlistSuccess') });

      // ✅ Fetch updated campaign data to reflect changes in UI
    await fetchCampaign(); 

    } catch (error) {
      console.error('Error updating campaign:', error.response.data);
    }

    setIsSaving(false);

  };

  const saveConfiguredWorkflow = async (configuredWorkflow) => {
    try {
      await api.post(`/campaigns/${id}/configure-workflow`, {
        configuredWorkflow,
      });

      setMessage({ type: 'success', text: t('campaigns.campaignSaveWorkflowSuccess') });

    } catch (error) {
      console.error('Error saving configured workflow:', error.response.data);

      setMessage({ type: 'error', text: 'Failed to save workflow.' });

    }

    setIsSaving(false);

  };

  const handleActivateCampaign = async () => {
    try {
      const response = await api.post(`/campaigns/activate/${id}`);
      setStatusMessage(response.data.message);
      fetchCampaign();
    } catch (error) {
      console.error('Error activating campaign:', error.response.data);
      setStatusMessage(t('campaign.activateFailed'));
    }
  };

  const handlePauseCampaign = async () => {
    try {
      const response = await api.post(`/campaigns/pause/${id}`);
      setStatusMessage(response.data.message);
      fetchCampaign();
    } catch (error) {
      console.error('Error pausing campaign:', error.response.data);
      setStatusMessage(t('campaign.pauseFailed'));
    }
  };

  const handleResumeCampaign = async () => {
    try {
      const response = await api.post(`/campaigns/resume/${id}`);
      setStatusMessage(response.data.message);
      fetchCampaign();
    } catch (error) {
      console.error('Error resuming campaign:', error.response.data);
      setStatusMessage(t('campaign.resumeFailed'));
    }
  };

  const handleSaveCampaignSettings = async () => {
    try {
      await api.post(`/campaigns/${id}/update-settings`, {
        timezone: campaignTimezone,
        schedule: schedule,
      });

      setMessage({ type: 'success', text: t('campaign.campaignSettingsSaved') });
      
    } catch (error) {
      console.error('Error saving campaign settings:', error.response.data);
    }
  };
  

  const renderActionButton = () => {
    switch (campaign.status) {
      case 'Concept':
        return <button onClick={handleActivateCampaign} className="btn btn-primary">{t('campaign.activate')}</button>;
      case 'Active':
        return <button onClick={handlePauseCampaign} className="btn btn-warning">{t('campaign.pause')}</button>;
      case 'Paused':
        return <button onClick={handleResumeCampaign} className="btn btn-success">{t('campaign.resume')}</button>;
      default:
        return null;
    }
  };



  const [view, setView] = useState('table');

  // Toggle view between workflow and table
  const toggleView = () => {
    setView(view === 'workflow' ? 'table' : 'workflow');
  };

  // Render the campaign workflow
  const renderWorkflow = () => {
    return (
      <div className="workflow-container">
        {Array.isArray(tasks) && campaign.configuredWorkflow ? (
          <CampaignWorkflow campaign={campaign} tasks={tasks} />
        ) : (
          <p>{t('campaign.noWorkflowData')}</p>
        )}
      </div>
    );
  };

  // Render the campaign tasks in a table
  const renderTable = () => {
    return (
      <div className='table-responsive'>
        <table className="table">
          <thead>
            <tr>
              <th>{t('campaign.task')}</th>
              <th>{t('campaign.leadName')}</th>
              <th>{t('campaign.status')}</th>
              <th>{t('campaign.createdAt')}</th>
            </tr>
          </thead>
          <tbody>
          {tasks.map(task => (
            <tr key={task._id.$oid}>
              <td>{task.type}</td>
              <td>{task.leadName}</td>
              <td>{task.status}</td>
              <td>
                {task.createdAt?.$date?.$numberLong
                  ? new Date(parseInt(task.createdAt.$date.$numberLong, 10)).toLocaleString()
                  : 'Invalid Date'}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );
  };



  // Define campaign status
  const isCampaignActive = campaign.status === 'Active';

  // Calculate statistics safely
  const totalLeads = campaign.leads?.length || 0;
  const connectedLeads = campaign.leads?.filter(lead => lead.status === 'Connected').length || 0;
  const messagesSent = tasks?.filter(task => task.type === 'MessageSent').length || 0;
  const responsesReceived = tasks?.filter(task => task.type === 'ResponseReceived').length || 0;




  return (
    <div className={`dashboard ${collapsed ? 'collapsed' : ''}`}>
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row page-row">
          <div className="campaigns col-md-12">
            <div className='activities-widget'>
              <div className="campaign-detail">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                  <h2>{t('campaign.configurationTitle')}</h2>
                  <div className="button-container" style={{ display: 'flex', gap: '10px' }}>
                    <button onClick={prevStep} disabled={currentStep === 1} className="btn btn-secondary">
                      <FontAwesomeIcon icon={faArrowLeft} /> {t('campaign.previous')}
                    </button>
                    <button onClick={nextStep} disabled={currentStep === 4} className="btn btn-primary">
                      {t('campaign.next')} <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  </div>
                </div>


                {/* Hide Progress Bar when Campaign is Active */}
                {!isCampaignActive && (
                  <div className="progress">
                    <div className="progress-bar progress-bar-striped bg-info" style={{ width: getProgressBarWidth(1) }}>
                      {currentStep === 1 && t('campaign.selectLeads')}
                    </div>
                    <div className="progress-bar progress-bar-striped bg-info" style={{ width: getProgressBarWidth(2) }}>
                      {currentStep === 2 && t('campaign.configureWorkflow')}
                    </div>
                    <div className="progress-bar progress-bar-striped bg-info" style={{ width: getProgressBarWidth(3) }}>
                      {currentStep === 3 && t('campaign.campaignSettings')}
                    </div>
                    <div className="progress-bar progress-bar-striped bg-info" style={{ width: getProgressBarWidth(4) }}>
                      {currentStep === 4 && t('campaign.activateCampaign')}
                    </div>
                  </div>
                )}

                <div className="alert-message">
                  {message && (
                    <AlertComponent
                      type={message.type}
                      message={message.text}
                      onClose={handleCloseAlert}
                    />
                  )}
                </div>

                <div className="steps">
                  <div className={`step ${currentStep === 1 ? 'active' : ''}`}>
                    {currentStep === 1 && (
                      <div>
                        <h2>{t('campaign.step1Title')}</h2>
                        <div className="filter-controls">
                        <select 
                          className="form-control" 
                          value={selectedLeadListId}  // ✅ Always reflects saved or selected value
                          onChange={handleLeadListChange} 
                          disabled={campaign.status !== "Concept"}
                        >
                          <option value="">{t('campaign.selectLeadList')}</option>
                          {leadLists.map((leadList) => (
                            <option key={leadList._id} value={leadList._id}>
                              {leadList.leadListTitle}
                            </option>
                          ))}
                        </select>


                        </div>
                        {campaign.status === "Concept" && (

                          <button 
                            className={`btn btn-primary save-button saveLeadListCampaignButton ${isSaving ? 'saving' : ''}`} 
                            onClick={updateCampaignWithLeadList} 
                            disabled={isSaving}
                          >
                            {isSaving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : t('campaign.saveLeadList')}
                          </button>
                        )}

                      </div>
                    )}
                  </div>
                  <div className={`step ${currentStep === 2 ? 'active' : ''}`}>
                    {currentStep === 2 && (
                      <div>
                        <h2>{t('campaign.step2Title')}</h2>
                        <SaveRestore id={id} saveWorkflow={saveConfiguredWorkflow} campaignStatus={campaign.status} setMessage={setMessage} />
                      </div>
                    )}
                  </div>
                  <div className={`step ${currentStep === 3 ? 'active' : ''}`}>
                    {currentStep === 3 && (
                      <div>
                        <h2>{t('campaign.step3Title')}</h2>
                        <div className="form-group">
                          <div className="timezone-settings">
                            <label>{t('campaign.selectTimezone')}</label>
                            <TimezoneSelector 
                              timezone={campaignTimezone} 
                              setTimezone={setCampaignTimezone} 
                              t={t} // Pass translation function
                            />
                          </div>
                        </div>


                        <h3>{t('campaign.operatingHours')}</h3>

                        

                        <div className="schedule-container">
      {Object.keys(schedule).map((day) => (
        <div key={day} className={`schedule-row ${schedule[day].active ? '' : 'disabled'}`}>
          {/* Left Side: Clock Icon + Day Name */}
          <div className="day-label">
            <FaClock className="clock-icon" />
            <span>{day}</span>
          </div>

          {/* Middle: Time Inputs (Only if active) */}
          {schedule[day].active && (
            <div className="schedule-inputs">
              <div className="time-input">
                <input
                  type="time"
                  className="form-control"
                  value={schedule[day].start}
                  onChange={(e) =>
                    setSchedule({
                      ...schedule,
                      [day]: { ...schedule[day], start: e.target.value },
                    })
                  }
                />
       
              </div>
              <span className="time-separator">-</span>
              <div className="time-input">
                <input
                  type="time"
                  className="form-control"
                  value={schedule[day].end}
                  onChange={(e) =>
                    setSchedule({
                      ...schedule,
                      [day]: { ...schedule[day], end: e.target.value },
                    })
                  }
                />
       
              </div>
            </div>
          )}

          {/* Right Side: Toggle Switch */}
          <label className="switch">
            <input
              type="checkbox"
              checked={schedule[day].active}
              onChange={() =>
                setSchedule({ ...schedule, [day]: { ...schedule[day], active: !schedule[day].active } })
              }
            />
            <span className="round slider-round"></span>
          </label>
        </div>
      ))}
    </div>

                            <button className="btn btn-primary" onClick={handleSaveCampaignSettings}>
                              {t('actions.save')}
                            </button>

                        <p>Skip duplicate leads in other campaign</p>
                        <p>Skip duplicate leads with team member</p>
                      </div>
                    )}
                  </div>
                  <div className={`step ${currentStep === 4 ? 'active' : ''}`}>
                    {currentStep === 4 && (
                      <div>
                        <h2>{t('campaign.step4Title')}</h2>
                        {renderActionButton()}
                        {statusMessage && <p>{statusMessage}</p>}

                        {campaign.status !== 'Concept' && (
                          <div>
                            <h3>{t('campaign.resultsTitle')}</h3>
                            <p>{t('campaign.status')}: {campaign.status}</p>

                            {/* Campaign Statistics */}
                            <div className="row statsCardRow page-row">
                              {/* Total Leads */}
                              <div className="col-md-3">
                                <div className="stats-card stats-card-primary border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
                                  <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                                    <FontAwesomeIcon icon={faUsers} className="stats-icon" />
                                  </div>

                                  <p className="stats-span font-medium text-sm card-label">
                                    {t('campaign.totalLeads')}
                                  </p>
                                  <div className="stats-card-span-value">
                                    <h1 className="stats-card-value">{totalLeads}</h1>
                                  </div>
                                </div>
                              </div>

                              {/* Connected Leads */}
                              <div className="col-md-3">
                                <div className="stats-card stats-card-primary border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
                                  <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                                    <FontAwesomeIcon icon={faUserPlus} className="stats-icon" />
                                  </div>

                                  <p className="stats-span font-medium text-sm card-label">
                                    {t('campaign.connectedLeads')}
                                  </p>
                                  <div className="stats-card-span-value">
                                    <h1 className="stats-card-value">{connectedLeads}</h1>
                                  </div>
                                </div>
                              </div>

                              {/* Messages Sent */}
                              <div className="col-md-3">
                                <div className="stats-card stats-card-primary border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
                                  <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                                    <FontAwesomeIcon icon={faEnvelope} className="stats-icon" />
                                  </div>

                                  <p className="stats-span font-medium text-sm card-label">
                                    {t('campaign.messagesSent')}
                                  </p>
                                  <div className="stats-card-span-value">
                                    <h1 className="stats-card-value">{messagesSent}</h1>
                                  </div>
                                </div>
                              </div>

                              {/* Responses Received */}
                              <div className="col-md-3">
                                <div className="stats-card stats-card-primary border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
                                  <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                                    <FontAwesomeIcon icon={faComments} className="stats-icon" />
                                  </div>

                                  <p className="stats-span font-medium text-sm card-label">
                                    {t('campaign.responsesReceived')}
                                  </p>
                                  <div className="stats-card-span-value">
                                    <h1 className="stats-card-value">{responsesReceived}</h1>
                                  </div>
                                </div>
                              </div>
                            </div>


                            <h3>{t('campaign.workflowOverview')}</h3>

                            {/* Toggle Button */}
                            <button className="view-toggle-btn" onClick={toggleView}>
                              <FaCogs /> {view === 'workflow' ? t('campaign.switchToTable') : t('campaign.switchToWorkflow')}
                            </button>

                            {/* Render Based on View */}
                            {view === 'workflow' ? renderWorkflow() : renderTable()}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignDetail;
