import React, { useEffect } from 'react';
import './Footer.css';

const FooterComponent = () => {

  return (
    <div className="footer bg-light">
      <p>Leadgenflow</p>
    </div>
  );
};

export default FooterComponent;
