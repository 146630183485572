import React, { useState } from "react";

const CustomNode = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);

  

  return (
    <div
      style={{

      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <strong>{data.label}</strong>

      {isHovered && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            background: "white",
            border: "1px solid black",
            borderRadius: 5,
            padding: 8,
            marginTop: 5,
            whiteSpace: "nowrap",
            boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
            zIndex: 10,
          }}
        >
          <p>Waiting: {data.taskCounts?.waiting}</p>
          <p>Pending: {data.taskCounts?.pending}</p>
          <p>Processing: {data.taskCounts?.processing}</p>
          <p>Completed: {data.taskCounts?.completed}</p>
        </div>
      )}
    </div>
  );
};

export default CustomNode;
