import React, { useState, useEffect } from 'react';
import './Leads.css';
import Sidebar from './../../components/Sidebar';
import NavBar from './../../components/Navbar';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';

import { Dropdown, DropdownButton } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FaEye } from 'react-icons/fa';

import Userfront from "@userfront/toolkit/react";

import { usePersistentCollapsedState } from './../../components/SidebarContext'; // Import useSidebar

import { CSVLink } from "react-csv"; // Import CSVLink from react-csv

import api from './../../api/axios'; // Import your axios instance

import { FaLinkedin, FaFileCsv, FaFileExport, FaTasks } from 'react-icons/fa';

import Pagination from './Pagination'; // Import the new Pagination component

import { useTranslation } from 'react-i18next';  // Import useTranslation hook

import AlertComponent from './../../components/Alert'; // Adjust the path as necessary
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Leads() {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { t, i18n } = useTranslation();  // Initialize translation
  const [userLanguage, setUserLanguage] = useState('en'); // State for user language

  const collapsed = usePersistentCollapsedState();

  const [message, setMessage] = useState(null);

  const [leadLists, setLeadLists] = useState([]);

  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]); 
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedListFilter, setSelectedListFilter] = useState('all');
  const [recordsPerPage, setRecordsPerPage] = useState(25); // Default records per page


  const [currentUser, setCurrentUser] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [newLead, setNewLead] = useState({
    name: '',
    organization: '',
    leadList: 'Default',
    status: 'active',
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [showNewLeadListModal, setShowNewLeadListModal] = useState(false);
  const [showNewLeadListCsvModal, setShowNewLeadListCsvModal] = useState(false);
  const [newLeadList, setNewLeadList] = useState({
    user: Userfront.user.email,
    leadListTitle: '',
    linkedinSearchUrl: '',
    maxQuantity: 10,
  });

  const [leadsFromCsv, setLeadsFromCsv] = useState([]);


  const handleCsvUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error('No file selected.');
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      console.log('CSV Content:', text); // Log the CSV content for debugging
      const json = csvToJson(text);
      console.log('Parsed JSON:', json); // Log the parsed JSON to verify the structure
      setLeadsFromCsv(json);
    };
    reader.readAsText(file);
  };
  
  const fetchUser = async () => {
    try {
      const email = Userfront.user.email;
      const userResponse = await api.get(`/user?email=${email}`);
      setCurrentUser(userResponse.data.user);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };
  
  const csvToJson = (csv) => {
    // Split CSV content by new lines
    const lines = csv.trim().split('\n');
  
    // Determine the delimiter by checking the first line
    const delimiter = lines[0].includes(';') ? ';' : ',';
  
    // Extract headers from the first line using the determined delimiter
    const headers = lines[0].split(delimiter).map(header => header.trim());
  
    // Initialize an array to hold the parsed data
    const result = [];
  
    // Process each line after the headers
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim();
  
      // Skip empty lines
      if (!line) continue;
  
      // Split line by the determined delimiter and handle extra spaces
      const currentline = line.split(delimiter).map(field => field.trim());
  
      // Check if the number of fields matches the headers
      if (currentline.length === headers.length) {
        const obj = {};
        // Map headers to fields
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      } else {
        console.warn(`Skipping invalid line: ${line}`);
      }
    }
  
    console.log('Parsed JSON:', result);
    return result;
  };
  
  

  
  // Handle individual checkbox change
  const handleCheckboxChange = (leadId) => {
    setSelectedLeads((prevSelected) =>
      prevSelected.includes(leadId)
        ? prevSelected.filter((id) => id !== leadId)
        : [...prevSelected, leadId]
    );
  };

  // Handle "Select All" checkbox change
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedLeads(filteredLeads.map((lead) => lead._id));
    } else {
      setSelectedLeads([]);
    }
  };

  // Delete selected leads
  const handleDeleteSelected = async () => {
    if (selectedLeads.length === 0) {
      alert('No leads selected');
      return;
    }

    try {
      // Make the API call to delete selected leads
      const response = await api.delete('/delete-leads', {
        data: { leadIds: selectedLeads },
      });

      if (response.status === 200) {
        // If the request is successful, filter out the deleted leads from the state
        const updatedLeads = leads.filter((lead) => !selectedLeads.includes(lead._id));
        setLeads(updatedLeads);
        setFilteredLeads(updatedLeads);
        setSelectedLeads([]);

        fetchLeads();
        fetchLeadLists();
        alert('Selected leads have been deleted');
      } else {
        alert('Failed to delete selected leads');
      }
    } catch (error) {
      console.error('Error deleting leads:', error);
      alert('An error occurred while deleting leads');
    }
  };



  const handleManageClick = (leadId) => {
    // Handle the "Manage" button click for the selected lead
    console.log(`Managing lead with ID ${leadId}`);

 //   window.location.replace(`/leads/${leadId}`);

    navigate(`/leads/${leadId}`);

  };

  // Calculate the index of the last lead on the current page
  const indexOfLastLead = currentPage * recordsPerPage;
  // Calculate the index of the first lead on the current page
  const indexOfFirstLead = indexOfLastLead - recordsPerPage;
  // Get the leads for the current page
  const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing records per page
  };

  // Handle search input change and filter leads based on the search term (Name column)
  useEffect(() => {
    if (searchTerm) {
      const filtered = leads.filter((lead) =>
        (lead.leadName ? lead.leadName.toLowerCase() : '').includes(searchTerm.toLowerCase())
      );
      setFilteredLeads(filtered);
    } else {
      // If searchTerm is undefined or empty, show all leads
      setFilteredLeads(leads);
    }
  }, [searchTerm, leads]);
  

  // Filter the leads based on the selected list and status filters
  useEffect(() => {
    const filtered = leads.filter((lead) => {
      if (selectedListFilter !== 'all' && lead.leadListTitle !== selectedListFilter) {
        return false;
      }
      return true;
    });
    setFilteredLeads(filtered);
    setCurrentPage(1); // Reset to the first page when changing the filter
  }, [selectedListFilter, leads]);

  const handleNewLeadSubmit = async () => {
    try {
      // Fetch the current user data from Userfront
      const user = await Userfront.currentUser();
  
      const response = await api.post('/leads', {
        name: newLead.name,
        organization: newLead.organization,
        leadList: newLead.leadList,
        status: newLead.status,
        user: user.email, // Include the user data
      });
  
      // Handle success
      console.log('New lead submitted:', response.data);
  
      // Close the modal
      setShowModal(false);
    } catch (error) {
      console.error('Error creating new lead:', error.response.data);
    }
  };


  // Fetch lead lists for the current user

    const fetchLeadLists = async () => {
      try {
        // Fetch the current user data from Userfront
        const user = await Userfront.user.email;

        // Make an API request to fetch lead lists for the user
        const response = await api.get(`/lead-lists?user=${user}`);

        // Update the lead lists state with the data from the backend
        setLeadLists(response.data);
      } catch (error) {
        console.error('Error fetching lead lists:', error.response.data);
      }
    };


    // Fetch user language setting from the database
  const fetchUserLanguage = async () => {
    try {
      const response = await api.get(`/user?email=${Userfront.user.email}`);
      setUserLanguage(response.data.language);
      i18n.changeLanguage(response.data.language); // Set language in i18next
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  };

  useEffect(() => {
    fetchUser();
    fetchLeadLists();
    fetchUserLanguage();
  }, []);

  const handleNewLeadListSubmit = async () => {
    try {
      setIsLoading(true);
      setErrorMessage(''); // Clear previous errors
  
      const response = await api.post('/leads', {
        user: Userfront.user.email,
        leadListTitle: newLeadList.leadListTitle,
        linkedinSearchUrl: newLeadList.linkedinSearchUrl,
        maxQuantity: newLeadList.maxQuantity,
        proxyServer: currentUser.proxyServer,
      });
  
      console.log('New lead submitted:', response.data);
  
      setSuccessMessage(t('leads.successMessage')); // Display a success message
      fetchLeadLists();
      fetchLeads();
  
      setTimeout(() => {
        setShowNewLeadListModal(false); // Close the modal after a short delay
      }, 500);
  
    } catch (error) {
      console.error('Error creating new lead:', error.response.data);
      setErrorMessage(t('leads.errorMessage')); // Show error inside the modal
    } finally {
      setIsLoading(false);
    }
  };
  


  

  const handleNewLeadListSubmitCsv = async () => {
    try {
      // Validate leads
      const invalidLeads = leadsFromCsv.filter(lead => 
      //  !lead.name?.trim() || 
        !lead.profileUrl?.startsWith('https://www.linkedin.com/in/') || 
        /\s/.test(lead.profileUrl)
      );
  
      if (invalidLeads.length > 0) {
        setErrorMessage('Some leads have missing names or invalid LinkedIn profile URLs.');
        setSuccessMessage('');
        return;
      }
  
      setIsLoading(true); // Set loading state to true
  
      const response = await api.post('/leads-from-csv', {
        user: Userfront.user.email,
        leadListTitle: newLeadList.leadListTitle,
        maxQuantity: newLeadList.maxQuantity,
        leads: leadsFromCsv,
      });
  
      console.log('New leads from CSV submitted:', response.data);
  
      // Close the modal
      setShowNewLeadListCsvModal(false);
  
      // Set success message and clear error message
      setSuccessMessage('Lead list created successfully');
      setErrorMessage('');
  
      fetchLeadLists();
      fetchLeads();
  
    } catch (error) {
      console.error('Error creating new lead list from CSV:', error.response?.data || error);
  
      // Set error message and clear success message
      setErrorMessage('Error creating lead list from CSV');
      setSuccessMessage('');
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };
  
  



  // Fetch leads for the current user
    const fetchLeads = async () => {
      try {
        // Fetch the current user data from Userfront
        const user = Userfront.user.email;

        // Make an API request to fetch leads for the user
        const response = await api.get(`/leads?user=${user}`);

        // Update the leads state with the data from the backend
        setLeads(response.data);
        setFilteredLeads(response.data); // Initialize filteredLeads with all leads
      } catch (error) {
        console.error('Error fetching leads:', error.response.data);
      }
    };


  useEffect(() => {
    fetchLeads();
  }, []);



  // Function to export leads data to CSV
  const exportLeadsToCSV = () => {
    const csvData = filteredLeads.map((lead) => ({
      id: lead._id,
      leadName: lead.leadName,
      profileUrl: lead.profileUrl,
      leadList: lead.leadListTitle,
    }));

    return csvData;
  };



  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);
  

  return (
    <div className={`dashboard ${collapsed ? 'collapsed' : ''}`}>
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row page-row">
          <div className="col-md-12">
            <div className='activities-widget'>
              <h2>{t('leads.title')}</h2>
  
              {/* Show spinner while loading */}
              {isLoading && <Spinner animation="border" role="status" />}
              {/* Show success or error message */}
              {successMessage && (
                <AlertComponent
                  type="success"
                  message={successMessage}
                  onClose={() => setSuccessMessage('')} // Clear success message
                />
              )}
              {errorMessage && (
                <AlertComponent
                  type="danger"
                  message={errorMessage}
                  onClose={() => setErrorMessage('')} // Clear error message
                />
              )}
  
              {/* Filter Section */}
              <div className="row">
                <div className='col-md-3 col-settings'>
                  <div className="search-bar">
                    <input
                      className='form-control'
                      type="text"
                      placeholder={t('leads.searchPlaceholder')}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                <div className='col-md-3 col-settings'>
                  <div className="filter-controls">
                    <select
                      className='form-control'
                      value={selectedListFilter}
                      onChange={(e) => setSelectedListFilter(e.target.value)}
                    >
                      <option value="all">{t('leads.selectLeadList')}</option>
                      {leadLists.map((leadList) => (
                        <option key={leadList._id} value={leadList.leadListTitle}>
                          {leadList.leadListTitle}
                        </option>
                      ))}
                    </select>
                  </div>
                  </div>
                  
                  <div className='col-md-3 col-settings'>
                  {selectedLeads.length !== 0 && (
                    <button
                      className="btn btn-danger"
                      onClick={handleDeleteSelected}
                      disabled={selectedLeads.length === 0}
                    >
                      {t('leads.deleteSelection')}
                    </button>
                  )}
                </div>
                  
                <div className='col-md-3 col-settings'>
                  <div className="create-button float-right">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={
                        <>
                          <FaTasks style={{ marginRight: '8px' }} /> {t('leads.manageLeads')}
                        </>
                      }
                      variant="primary"
                      className="importButtonLeads"
                    >
                      <Dropdown.Item onClick={() => setShowNewLeadListModal(true)}>
                        <FaLinkedin style={{ marginRight: '8px', color: '#0e76a8' }} /> {t('leads.importFromLinkedIn')}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setShowNewLeadListCsvModal(true)}>
                        <FaFileCsv style={{ marginRight: '8px', color: '#28a745' }} /> {t('leads.importFromCSV')}
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <CSVLink
                          data={exportLeadsToCSV()}
                          filename={"leads.csv"}
                          className="btn btn-link p-0 text-dark"
                        >
                          <FaFileExport style={{ marginRight: '8px', color: '#007bff' }} /> {t('leads.exportToCSV')}
                        </CSVLink>
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className='col-md-12 col-settings'>
                  {/* Table */}

                  {filteredLeads.length === 0 && (
                    <div className="empty-inbox-div">
                      <img src="/images/leads.png" className="empty-inbox-img" />
                      <p>Start with importing your leads.</p>

                      <div className="import-buttons">
                        <button onClick={() => setShowNewLeadListModal(true)}>
                          <FaLinkedin />
                          {t('leads.importFromLinkedIn')}
                        </button>

                        <button onClick={() => setShowNewLeadListCsvModal(true)}>
                          <FaFileCsv />
                          {t('leads.importFromCSV')}
                        </button>
                      </div>
                    </div>
                  )}


                  {filteredLeads.length > 0 && (

                  <div className='table-responsive'>
                    <table className="table">
                      <thead>
                        <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={handleSelectAllChange}
                            checked={selectedLeads.length === filteredLeads.length && filteredLeads.length > 0}
                          />
                        </th>
                          <th>{t('leads.table.image')}</th>
                          <th>{t('leads.table.name')}</th>
                          <th>{t('leads.table.profileUrl')}</th>
                          <th>{t('leads.table.leadList')}</th>
                          <th>{t('leads.table.manage')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentLeads.map((lead) => (
                          <tr key={lead._id}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedLeads.includes(lead._id)}
                                onChange={() => handleCheckboxChange(lead._id)}
                              />
                            </td>
                            <td>
                              {lead.profileImageUrl &&
                                <img
                                  src={lead.profileImageUrl}
                                  alt={t('leads.avatar')}
                                  width="80"
                                  height="80"
                                />
                              }
                            </td>
                            <td>{lead.leadName}</td>
                            <td>{lead.profileUrl}</td>
                            <td>{lead.leadListTitle}</td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle variant="link" id={`dropdown-lead-${lead._id}`}>
                                  <FontAwesomeIcon icon={faEllipsisV} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => handleManageClick(lead._id)}>
                                    <FaEye style={{ marginRight: '8px' }} /> {t('leads.table.view')}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  )}

                </div>

                {filteredLeads.length > 0 && (
                  <div className='row'>

                    <div className='col-md-6'>
                      <div className="records-per-page">
                        <span>{t('leads.rowsPerPage')} </span>
                        <select
                          className=""
                          value={recordsPerPage}
                          onChange={handleRecordsPerPageChange}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      {/* Pagination */}
                      <Pagination
                        className="float-right"
                        currentPage={currentPage}
                        totalItems={filteredLeads.length}
                        recordsPerPage={recordsPerPage}
                        paginate={paginate}
                      />
                    </div>

                </div>

                )}

              </div>
            </div>
          </div>
        </div>
      </div>
  
      {/* Modal for adding a new lead */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('leads.createNewLead')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>{t('leads.name')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('leads.enterName')}
                value={newLead.name}
                onChange={(e) => setNewLead({ ...newLead, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('leads.organization')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('leads.enterOrganization')}
                value={newLead.organization}
                onChange={(e) => setNewLead({ ...newLead, organization: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('leads.leadList')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('leads.enterLeadList')}
                value={newLead.leadList}
                onChange={(e) => setNewLead({ ...newLead, leadList: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('leads.status')}</Form.Label>
              <Form.Control
                as="select"
                value={newLead.status}
                onChange={(e) => setNewLead({ ...newLead, status: e.target.value })}
              >
                <option value="active">{t('leads.active')}</option>
                <option value="inactive">{t('leads.inactive')}</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('leads.close')}
          </Button>
          <Button variant="primary" onClick={handleNewLeadSubmit}>
            {t('leads.createLead')}
          </Button>
        </Modal.Footer>
      </Modal>
  
      {/* Modal for adding a new lead list */}
      <Modal show={showNewLeadListModal} onHide={() => setShowNewLeadListModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('leads.createNewLeadList')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>{t('leads.name')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('leads.enterName')}
                value={newLeadList.leadListTitle}
                onChange={(e) => setNewLeadList({ ...newLeadList, leadListTitle: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('leads.linkedinSearchUrl')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('leads.enterLinkedInSearchUrl')}
                value={newLeadList.linkedinSearchUrl}
                onChange={(e) => setNewLeadList({ ...newLeadList, linkedinSearchUrl: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('leads.maxQuantity')}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t('leads.enterMaxQuantity')}
                value={newLeadList.maxQuantity}
                onChange={(e) => setNewLeadList({ ...newLeadList, maxQuantity: parseInt(e.target.value) })}
              />
            </Form.Group>
            {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNewLeadListModal(false)} disabled={isLoading}>
            {t('leads.close')}
          </Button>
          <Button variant="primary" onClick={handleNewLeadListSubmit} disabled={isLoading}>
            {isLoading ? <Spinner as="span" animation="border" size="sm" /> : t('leads.createLeadList')}
          </Button>
        </Modal.Footer>
      </Modal>

  
 




      {/* Modal for uploading a CSV file */}
      <Modal show={showNewLeadListCsvModal} onHide={() => setShowNewLeadListCsvModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('leads.uploadCSV')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>{t('leads.name')}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={newLeadList.leadListTitle}
                onChange={(e) => setNewLeadList({ ...newLeadList, leadListTitle: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('leads.chooseFile')}</Form.Label>
              <Form.Control type="file" accept=".csv" onChange={handleCsvUpload} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Max Quantity</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter max quantity"
                value={newLeadList.maxQuantity}
                onChange={(e) =>
                  setNewLeadList({ ...newLeadList, maxQuantity: parseInt(e.target.value) })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNewLeadListCsvModal(false)}>
          {t('leads.close')}
          </Button>
          <Button variant="primary" onClick={handleNewLeadListSubmitCsv}>
          {t('leads.upload')}
          </Button>
        </Modal.Footer>
      </Modal>



    </div>
  );
  
}

export default Leads;
